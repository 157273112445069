.search-page {
  max-width: 1250px;
  margin: 90px auto;
}
.search-bar-wrapper {
  margin: 0px 16px 0px 16px;
  flex: 1;
}
.search-controls {
  display: flex;
  max-width: 100%;
  margin: 0px 25px;
  align-items: flex-end;
}
