.swimlane-table {
  width: 100%;

  .rkp-table__cell {
    overflow: visible;
    align-items: flex-start;
  }
}
.table-icon {
  color: #0073d1;
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding-right: 5px;
}
