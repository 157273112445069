.asset-modal {
  position: relative;
  .asset-modal-video-player {
    width: calc(100% + 70px);
    height: calc(100% + 70px);
    background-color: #000000cc;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -35px;
    left: -35px;
  }
  .text-title {
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .trailer-link {
    cursor: pointer;
    color: #0073d1;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
  }
  .trailers-header {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
  }
  .asset-media {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    width: 370px;
  }

  .asset-text {
    width: 410px;
    margin-bottom: 20px;
    line-height: 22px;
  }
  .close-video-player {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 50px;
    font-weight: bold;
  }

  .trailer-status-group {
    font-size: 14px;
  }

  .trailer-status {
    font-weight: bold;
  }

  .poster-image {
    width: 96px;
    height: 145px;
    background-color: #002747;
    margin-right: 6px;
  }

  .hero-image {
    width: 260px;
    height: 145px;
    background-color: #002747;
  }
  .trailer-name {
    margin-left: 20px;
  }
}
