.app-modal {
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 35px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid #d8dde6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
}

.modal-backdrop {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff99;
  padding: 50px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
}

.modal-buttons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
