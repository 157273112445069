.reusable-button {
  outline: none;
  background-color: #0073d1;
  color: white;
  font-weight: bold;
  border: none;
  width: 150px;
  height: 42px;
  border-radius: 4px;
  cursor: pointer;
}

.reusable-button.negative {
  background-color: #d6312b;
}

.reusable-button.outline {
  background-color: transparent;
  color: #0073d1;
  border: 1px solid #0073d1;
}

.reusable-button.outline.negative {
  background-color: transparent;
  color: #d6312b;
  border: 1px solid #d6312b;
}
