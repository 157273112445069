.image-reports-table {
  width: 100%;

  .rkp-table__cell {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rkp-table__row {
    position: relative;
  }
  .rkp-table__headings-title {
    margin-bottom: 0px;
    // font-size: 20px;
    font-weight: bold;
    position: relative;
  }
  .active-row {
    width: 10px;
    background-color: #0073d1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .play-image {
    color: #0073d1;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-right: 5px;
  }
  .play-image:hover {
    background-color: #d8dde6;
  }
  .delete-image-button {
    height: 100%;
    width: 70px;
    // display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-image-button:hover {
    background-color: #d8dde6;
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title-container {
    overflow: hidden;
  }
}
