.unauthorized {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;

  &__title {
    width: 377px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    color: #63738a;
    margin: 24px 0;
  }

  &__copy {
    font-size: 16px;
  }
}
