.reports-containter {
  position: relative;
  padding: 16px 40px 30px;
  max-width: 1400px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__add {
    position: absolute;
    top: 16px;
    right: 40px;
    width: 158px;
    height: 44px;
    border-radius: 4px;
    background-color: #0073d1;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &:hover {
      color: #ffffff;
    }
  }

  &__title {
    align-self: flex-start;
    width: 100%;
    line-height: normal;
    margin-bottom: 30px !important;
  }
}

.reports-header {
  margin: 30px 0px 40px 0px;
}
