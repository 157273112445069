.swimlane-page {
  max-width: 1250px;
  margin: 80px auto;
}
.back-button {
  margin-top: 30px;
  margin-bottom: 5px;
}
.searchPage-Title {
  margin: 25px 25px 25px 0;
}
