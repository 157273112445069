.card-triplet-container {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .card {
    width: 389px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 0px 15px 0px;
    height: 109px;
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d8dde6;
    background-color: #ffffff;
  }
  .card-info {
    height: 24px;
    margin: 0 auto;
    font-size: 28px;
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: 4px;
    color: #0062b2;
  }
  .card-title {
    height: 24px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 2px;
    text-align: center;
    color: #63738a;
  }
}
