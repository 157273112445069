.title-table {
  margin: 25px;

  .rkp-table__cell {
    overflow: visible;
    align-items: flex-start;
  }
  .rkp-table__empty {
    min-height: 600px;
    font-size: 20px;
  }
}
