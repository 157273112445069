// Changes Saved Error / Success Messages on Cards
.changes-message-enter {
  opacity: 0;
  transform: translateY(-4px);

  &-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s linear;
  }
}

.changes-message-exit {
  opacity: 1;
  transform: translateY(0);
  &-active {
    opacity: 0;
    transform: translateY(-4px);
    transition: all 0.3s ease-in;
  }
}
