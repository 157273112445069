.search-page {
  max-width: 1250px;
  margin: 80px auto;
}
.search-bar-wrapper-swimLanes {
  margin-right: 15px;
  flex: 1;
}
.search-controls-swimLanes {
  display: flex;
  max-width: 100%;
  margin-bottom: 40px;
  align-items: flex-end;
}
.searchSwimlanes-button {
  padding: 0px 5px;
  width: 300px;
}
.searchPage-Title {
  margin: 25px 25px 25px 0;
}
