.barchart-wrapper {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 30px 0px;
  border-radius: 4px;
  border: 1px solid #d8dde6;
  background-color: #ffffff;
}

#barchartdiv {
  width: 100%;
  height: 500px;
}
.chart-loader {
  padding-top: 150px;
}
