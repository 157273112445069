#chartdiv {
  width: 100%;
  height: 500px;
  margin: 0px 0px 30px 0px;
  border-radius: 4px;
  border: 1px solid #d8dde6;
  background-color: #ffffff;
}
.chart-loader {
  padding-top: 150px;
}
