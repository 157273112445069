.title-page {
  margin: 60px auto;

  .loader-container {
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-type {
    font-family: SpectrumSans;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    width: max-content;
    display: flex;
    flex-direction: column;
  }

  .title-images {
    height: 250px;
    border: 1px solid #f1f1f1;
  }

  .title-banner {
    background-color: #003561;
    width: 100%;
    padding: 24px 40px;
    color: white;
    font-size: 36px;
    font-family: 'SpectrumSans-Bold';
  }
  .asset-page-main {
    padding: 40px;
  }

  .asset-metadata {
    padding: 40px;
    background-color: #003561;
    color: white;
    border-radius: 4px;
    max-width: 1200px;
    margin: 0px auto;
  }

  .asset-page-trailers {
    border-radius: 4px;
    padding: 40px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 40px auto 0px auto;
  }

  .assets-page-trailer {
    margin-bottom: 35px;
  }

  .set-as-primary {
    font-weight: bold;
    color: #005499;
    margin-right: 15px;
    cursor: pointer;
  }
  .primaryIneligible {
    color: #9ba9bd99;
    font-weight: bold;
    cursor: not-allowed;
    margin-right: 15px;
  }
  .remove-trailer {
    font-weight: bold;
    color: #d6312b;
    cursor: pointer;
  }
  .primary-label {
    background-color: #005499;
    color: white;
    border-radius: 3px;
    padding: 3px;
    margin-left: 10px;
  }
  .primary-trailer {
    background-color: #9ba9bd;
  }

  .trailer-thumbnail {
    width: 550px;
  }

  .trailer-thumbnail-wrapper {
    position: relative;
    cursor: pointer;
    width: max-content;
  }
  .thumbnail-play-arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .trailer-actions {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 640px;
  }
  .trailer-action-button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .text-title {
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
