.footer {
  background-color: #004366;
  color: #ffffff;
  max-height: 3rem;
  padding: 2rem 0.4rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
}

.flex-container-left {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.flex-container-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.footer-logo {
  margin: 10px;
  height: 1.675em;
}

.spacer-1 {
  margin-top: 15px;
  padding-right: 0.5em;
  font-family: Rutledge;
  font-size: 1.2rem;
}

.spacer-2 {
  font-family: Rutledge;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-right: 10px;
}
