.asset-loader {
  top: 0;
  height: 700px;
}
.trailers-side-pannel {
  width: 400px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid #d9dee6;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  min-height: 100vh;
}
.asset-card {
  border: solid 1px #d8dde6;
  padding: 16px;
  display: flex;
}
.asset-title {
  color: #0073d1;
  font-family: 'Rutledge-Medium';
  text-decoration: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.asset-meta-data {
  font-family: 'SpectrumSans';
  color: #2f313a;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.asset-meta-data-value {
  padding: 0px 5px;
}

.asset-image {
  width: 49px;
  height: 74px;
  background-color: #002747;
  margin-right: 16px;
}

.asset-title-meta-data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.add-to-title {
  color: #0073d1;
  font-size: 16px;
  font-family: 'Rutledge-Medium';
  margin: 14px 0px 0px auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.associate-confirm {
  position: absolute;
  top: 40vh;
  height: 200px;
  width: 300px;
  background-color: white;
  left: 100px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 16px;
}

.title-search-tip {
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  color: #9a9a9a;
  margin: 100px auto 0px auto;
  text-align: center;
  line-height: 35px;
}
