.image-report-page {
  display: flex;
  margin: 50px auto;

  .report-title {
    font-size: 28px;
    font-weight: bold;
  }
  .export-button {
    width: 210px;
    height: 45px;
  }

  .export-button.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .image-reports-filter {
    cursor: pointer;
    margin: 35px auto 0px auto;
    display: flex;
    justify-content: space-between;
    width: 190px;
  }
  .image-reports-filter.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .image-report-page-banner {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 225px;
    background-color: #004366;
    padding: 18px 10% 0px 10%;
    color: white;
  }
  .back-to-reports {
    cursor: pointer;
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    align-items: center;
    height: max-content;
    margin-bottom: 10px;
  }
  .view-tabs {
    display: flex;
    align-items: flex-end;
  }

  .view-tab {
    padding: 16px 22px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 3px;
    border-bottom: 3px solid transparent;
  }

  .view-tab.active {
    font-weight: bold;
    border-bottom: 3px solid #0073d1;
  }

  .image-report-main {
    position: relative;
    padding: 16px 40px 30px;
    width: 100%;
    min-width: 650px;
    max-width: 1400px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__add {
      position: absolute;
      top: 16px;
      right: 40px;
      width: 158px;
      height: 44px;
      border-radius: 4px;
      background-color: #0073d1;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }

    &__title {
      align-self: flex-start;
      width: 100%;
      line-height: normal;
      margin-bottom: 30px !important;
    }
  }

  .image-report-header {
    margin: 30px 0px;
  }

  .video-player-wrapper {
    margin: 50px;
  }

  .select-video {
    width: 640px;
    height: 360px;
    background-color: #002747;
    color: white;
    font-size: 25px;
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .play-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 4px solid white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .image-report-table-box {
    overflow: hidden;
    width: 100%;
  }

  .image-table-count {
    transform: translate(0px, 20px);
    display: flex;
    justify-content: flex-end;
  }
}
