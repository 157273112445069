.nav {
  width: 100%;
  background-color: white;
  padding: 10px 40px;
  display: flex;
  top: 0;
  z-index: 40;
  position: fixed;

  h1 {
    font-size: 24px;
    font-weight: normal;
    color: #2f313a;
    margin: 0 40px 0 0;
    letter-spacing: -0.28px;
  }

  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    margin-right: 23px;
  }

  &__link {
    text-decoration: none;

    &-icon {
      margin-right: 5px;
    }

    &-badge {
      background-color: #9ba9bd;
      margin-left: 9px;
    }

    &:focus {
      outline: none;
      & > .nav__link-content {
        border: 2px solid #0073d1;
      }
    }

    &:hover {
      text-decoration-color: black;
    }

    &-content {
      position: relative;
      height: 36px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      color: black;

      &:focus {
        outline: none;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: attr(data-link);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    &--active {
      .nav__link-content {
        font-weight: bold;
        color: #0073d1;
      }

      .ki {
        fill: #0073d1 !important;
      }

      .nav__link-badge {
        background-color: #0073d1;
      }
    }
  }

  &__new-trailers {
    &:hover {
      text-decoration: none;

      .nav__new-trailers-text {
        text-decoration: underline;
      }
    }
  }

  .kite-con-menu {
    position: absolute;
    right: 42px;
    top: 26px;
    min-width: 0;

    &__button {
      padding: 0;
    }

    &__menu {
      display: flex;
      flex-direction: column;
    }
  }
}
