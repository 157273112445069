.title-details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-details-modal-content {
  height: 800px;
  margin-bottom: 10px;
  border: 1px solid #d8dde6;
  border-radius: 3px;
  padding: 15px;
  max-width: 80vw;
  max-height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}
.detail-line {
  margin-bottom: 10px;
}
.detail-scroll {
  height: max-content;
}
