@import '~@kite/react-kite/dist/react-kite.css';
@import '~@kite/react-kite/dist/kite-icons.min.css';
@import '~@kite/react-kite-plus/dist/react-kite-plus.css';
@import '~@kite/react-kite-plus/dist/kite-icons.min.css';

// global imports
@import './styles/global/animations';

@font-face {
  font-family: 'Rutledge-Light';
  font-style: normal;
  font-weight: 300;
  src: local('Rutledge Light'),
    url('/assets/fonts/Rutledge/Rutledge-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'SpectrumSans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/SpectrumSans.woff');
}
@font-face {
  font-family: 'SpectrumSans-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/SpectrumSans_Bld.woff.woff');
}
@font-face {
  font-family: 'Rutledge';
  font-style: normal;
  font-weight: 400;
  src: local('Rutledge Regular'),
    url('/assets/fonts/rutledge-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rutledge-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Rutledge Medium'),
    url('/assets/fonts/rutledge-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Rutledge-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Rutledge Bold'),
    url('/assets/fonts/rutledge-bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Rutledge, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.center-text {
  text-align: center;
}

.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

.width-100 {
  width: 100%;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.hidden {
  visibility: hidden;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.margin-25px {
  margin: 25px;
}
.margin-50px {
  margin: 50px;
}
.no-margin {
  margin: 0px;
}
.padding-16px {
  padding: 16px;
}

.bold {
  font-weight: bold;
}

.wrap {
  flex-wrap: wrap;
}

.margin-left-auto {
  margin-left: auto;
}
