.image-reports-page {
  display: flex;
  margin: 50px auto;

  .manual-trigger {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 500px;
    margin-left: auto;
    justify-content: space-between;
  }
  .manual-button {
    width: 200px;
  }
  .image-reports-main {
    position: relative;
    padding: 16px 40px 30px;
    width: 100%;
    min-width: 650px;
    max-width: 1400px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__add {
      position: absolute;
      top: 16px;
      right: 40px;
      width: 158px;
      height: 44px;
      border-radius: 4px;
      background-color: #0073d1;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        color: #ffffff;
      }
    }

    &__title {
      align-self: flex-start;
      width: 100%;
      line-height: normal;
      margin-bottom: 30px !important;
    }
  }

  .image-reports-header {
    margin: 30px 0px;
  }

  .video-player-wrapper {
    margin: 50px;
  }

  .select-video {
    width: 640px;
    height: 360px;
    background-color: #002747;
    color: white;
    font-size: 25px;
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .play-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 4px solid white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .image-reports-table-box {
    overflow: hidden;
    width: 100%;
  }

  .image-table-count {
    transform: translate(0px, 20px);
    display: flex;
    justify-content: flex-end;
  }
}

.kite-pagination {
  width: 100%;

  .page-actions {
    justify-content: end;
  }

  .page-summary {
    float: right;
  }

  .page-arrow {
    padding: 10px 5px 5px 5px;
  }
}
